import FontPicker from "font-picker-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DigitalCard from "../../components/digital-card";
import "./styles.css";

function DownloadCard() {
  const [card, setCard] = useState(null);
  const [settings, setSettings] = useState(null);
  let { uid } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_SERVER_URL}/card/${uid}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setCard(response.card);
        setSettings(response.settings);

        if (response.settings?.bgColor) {
          document.querySelector("html").style.backgroundColor =
            response.settings.bgColor;
        }
      })
      .catch((error) => console.log(error));
  }, [uid]);

  return (
    <div className={"render"}>
      {card && (
        <DigitalCard
          isEditing={false}
          cardData={card}
          color={(settings && settings.color) || "#000000"}
          bgColor={(settings && settings.bgColor) || "#FFFFFF"}
          bgImage={(settings && settings.bgImage) || ""}
          fontSizeMultiplier={(settings && settings.fontSizeMultiplier) || 1}
        />
      )}

      {settings && settings.font && (
        <FontPicker
          apiKey={process.env.REACT_APP_GOOGLE_FONTS_API_KEY}
          activeFontFamily={settings.font.family}
        />
      )}
    </div>
  );
}

export default DownloadCard;
