import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faAt,
  faGlobe,
  faMapMarker,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import person from "../../assets/images/person.png";
import firebase from "../../firebase";
import { toast } from "react-toastify";
import "./styles.css";
import * as filestack from "filestack-js";

function getGridTemplate(count) {
  switch (count) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return "repeat(auto-fit, minmax(56px, 1fr))";
    case 6:
      return "repeat(auto-fit, minmax(84px, 1fr))";
    case 7:
    case 8:
      return "repeat(auto-fit, minmax(64px, 1fr))";
    default:
      return "repeat(auto-fit, minmax(56px, 1fr))";
  }
}

const autoSelect = (evt, stage) => (stage === 1 ? evt?.target?.select() : true);

function DigitalCard(props) {
  const {
    stage,
    isEditing,
    cardData,
    cardSettings,
    font,
    color,
    bgColor,
    bgImage,
    fontSizeMultiplier,
    buttons,
  } = props;

  const [editingCard, setEditingCard] = useState({});
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const {
    name,
    title,
    description,
    address,
    email,
    phone,
    whatsapp,
    website,
    twitter,
    facebook,
    instagram,
    tiktok,
    location,
  } = editingCard;

  useEffect(() => {
    if (cardSettings && font && color && bgColor && fontSizeMultiplier) {
      firebase.saveSettings({
        ...cardSettings,
        font,
        color,
        bgColor,
        bgImage,
        fontSizeMultiplier,
      });
    }
  }, [cardSettings, font, color, bgColor, bgImage, fontSizeMultiplier]);

  useEffect(() => {
    if (buttons) {
      setEditingCard((editingCard) => ({
        ...editingCard,
        ...buttons,
      }));
    }
  }, [buttons]);

  useEffect(() => {
    let isCancelled = false;

    setEditingCard(cardData);

    if (cardData.avatar) {
      if (cardData.avatar.includes("filestack")) {
        setAvatarUrl(cardData.avatar);
      } else {
        firebase
          .storage()
          .ref()
          .child(cardData.avatar)
          .getDownloadURL()
          .then((url) => {
            if (isCancelled) return;

            setAvatarUrl(url);
          })
          .catch(console.error);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [cardData]);

  useEffect(() => {
    if (
      !isEditing &&
      Object.keys(editingCard).length &&
      !editingCard.isDefault &&
      JSON.stringify(cardData) !== JSON.stringify(editingCard)
    ) {
      firebase.saveData(editingCard);
    }
  }, [isEditing, cardData, editingCard]);

  useEffect(() => {
    if (showFilePicker) {
      const client = filestack.init("AixHSJPoCTUCpjEaFWgSsz");
      client
        .picker({
          fromSources: ["local_file_system"],
          accept: ["image/*"],
          onClose: () => {
            setShowFilePicker(false);
          },
          onUploadDone: ({ filesUploaded, filesFailed }) => {
            if (filesFailed.length) {
              toast.error("Uploading failed, please try again!", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
              });
            }

            if (filesUploaded.length) {
              setAvatarUrl(filesUploaded[0].url);
              setEditingCard((card) => ({
                ...card,
                avatar: filesUploaded[0].url,
              }));
            }
          },
        })
        .open();
    }
  }, [showFilePicker]);

  const updatedFontSizeMultiplier =
    fontSizeMultiplier > 1 ? fontSizeMultiplier * 0.1 + 1 : 1;

  const buttonsCount = [
    phone,
    email,
    whatsapp,
    website,
    twitter,
    facebook,
    instagram,
    tiktok,
  ].reduce((acc, val) => (val ? acc + 1 : acc), 0);

  return (
    <div
      className={"digital-card apply-font"}
      style={{
        color: color,
        backgroundColor: bgColor,
      }}
    >
      <div
        className={"bg-image"}
        style={{
          backgroundImage: bgImage?.trim() ? `url(${bgImage})` : "none",
          backgroundSize: "cover",
        }}
      />

      {isEditing ? (
        <>
          <div className={"header"}>
            {avatarUrl ? (
              <img
                className={"avatar"}
                src={avatarUrl}
                onClick={() => {
                  setShowFilePicker(true);
                }}
                alt={`${name}'s avatar`}
              />
            ) : (
              <img
                className={"avatar"}
                src={person}
                onClick={() => {
                  setShowFilePicker(true);
                }}
                alt={`${name}'s avatar`}
              />
            )}

            <input
              placeholder={"Enter your name"}
              className={"name"}
              style={{ fontSize: 24 * updatedFontSizeMultiplier }}
              value={name || ""}
              autoCorrect="off"
              autoCapitalize="none"
              onChange={(evt) =>
                setEditingCard((card) => ({
                  ...card,
                  name: evt.target.value,
                }))
              }
              onFocus={(e) => autoSelect(e, stage)}
            />

            <input
              placeholder={"Enter your title"}
              style={{ fontSize: 14 * updatedFontSizeMultiplier }}
              className={"title"}
              value={title || ""}
              autoCorrect="off"
              autoCapitalize="none"
              onChange={(evt) =>
                setEditingCard((card) => ({
                  ...card,
                  title: evt.target.value,
                }))
              }
              onFocus={(e) => autoSelect(e, stage)}
            />
          </div>
          <div className={"content"}>
            <textarea
              rows={6}
              placeholder={"Short description"}
              className={"description"}
              style={{ fontSize: 16 * updatedFontSizeMultiplier }}
              value={description || ""}
              onChange={(evt) =>
                setEditingCard((card) => ({
                  ...card,
                  description: evt.target.value,
                }))
              }
              onFocus={(e) => autoSelect(e, stage)}
            />

            <div
              className={"icons"}
              style={{
                visibility: stage === 1 ? "hidden" : "visible",
                backgroundColor: color,
                color: bgColor,
                gridTemplateColumns: getGridTemplate(buttonsCount),
              }}
            >
              {phone && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faPhoneAlt} size={"2x"} />
                </span>
              )}

              {whatsapp && (
                <span className={"icon whatsapp"}>
                  <FontAwesomeIcon icon={faWhatsapp} size={"3x"} />
                </span>
              )}

              {email && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faAt} size={"2x"} />
                </span>
              )}

              {twitter && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faTwitter} size={"2x"} />
                </span>
              )}

              {facebook && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faFacebook} size={"2x"} />
                </span>
              )}

              {instagram && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faInstagram} size={"2x"} />
                </span>
              )}

              {tiktok && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faTiktok} size={"2x"} />
                </span>
              )}

              {location && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faMapMarker} size={"2x"} />
                </span>
              )}

              {website && (
                <span className={"icon"}>
                  <FontAwesomeIcon icon={faGlobe} size={"2x"} />
                </span>
              )}
            </div>
          </div>
          <div className={"footer"}>
            <input
              placeholder={"Enter address"}
              className={"address"}
              value={address || ""}
              onChange={(evt) =>
                setEditingCard((card) => ({
                  ...card,
                  address: evt.target.value,
                }))
              }
              autoCorrect="off"
              autoCapitalize="none"
              onFocus={(e) => autoSelect(e, stage)}
            />

            <div className={"contact-container"}>
              <p className={"phone"}>{phone}</p>

              <input
                placeholder={"Enter email"}
                className={"email"}
                value={email || ""}
                onChange={(evt) =>
                  setEditingCard((card) => ({
                    ...card,
                    email: evt.target.value,
                  }))
                }
                type="email"
                autoCorrect="off"
                autoCapitalize="none"
                onFocus={(e) => autoSelect(e, stage)}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={"header"}>
            {avatarUrl ? (
              <img
                className={"avatar"}
                src={avatarUrl}
                alt={`${name}'s avatar`}
              />
            ) : (
              <img className={"avatar"} src={person} alt={`${name}'s avatar`} />
            )}
            <p
              className={"name"}
              style={{ fontSize: 24 * updatedFontSizeMultiplier }}
            >
              {name}
            </p>
            <p
              className={"title"}
              style={{ fontSize: 14 * updatedFontSizeMultiplier }}
            >
              {title}
            </p>
          </div>

          <div
            className={"content"}
            style={{ fontSize: 16 * updatedFontSizeMultiplier }}
          >
            <p className={"description"}>{description}</p>

            <div
              className={"icons"}
              style={{
                visibility: stage === 1 ? "hidden" : "visible",
                backgroundColor: color,
                color: bgColor,
                gridTemplateColumns: getGridTemplate(buttonsCount),
              }}
            >
              {phone && (
                <a
                  href={`tel:${phone}`}
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faPhoneAlt} size={"2x"} />
                </a>
              )}

              {whatsapp && (
                <a
                  href={`https://api.whatsapp.com/send?phone=${whatsapp}`}
                  className={"icon whatsapp"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faWhatsapp} size={"3x"} />
                </a>
              )}

              {email && (
                <a
                  href={`mailto:${email}`}
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faAt} size={"2x"} />
                </a>
              )}

              {twitter && (
                <a
                  href={
                    twitter.includes("http")
                      ? twitter
                      : `https://www.twitter.com/${twitter}`
                  }
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faTwitter} size={"2x"} />
                </a>
              )}

              {facebook && (
                <a
                  href={
                    facebook.includes("http")
                      ? facebook
                      : `https://www.facebook.com/${facebook}`
                  }
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faFacebook} size={"2x"} />
                </a>
              )}

              {instagram && (
                <a
                  href={
                    instagram.includes("http")
                      ? instagram
                      : `https://www.instagram.com/${instagram}`
                  }
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faInstagram} size={"2x"} />
                </a>
              )}

              {tiktok && (
                <a
                  href={
                    tiktok.includes("http")
                      ? tiktok
                      : `https://www.tiktok.com/@${tiktok}`
                  }
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faTiktok} size={"2x"} />
                </a>
              )}

              {location && (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`}
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faMapMarker} size={"2x"} />
                </a>
              )}

              {website && (
                <a
                  href={website.replace(/^(?:(ht)tp(s?):\/\/)?/g, "https://")}
                  className={"icon"}
                  target="_blank"
                  rel={"noreferrer"}
                >
                  <FontAwesomeIcon icon={faGlobe} size={"2x"} />
                </a>
              )}
            </div>
          </div>

          <div
            className={"footer"}
            style={{ fontSize: 13 * updatedFontSizeMultiplier }}
          >
            <p className={"address"}>{address}</p>

            <div className={"contact-container"}>
              <a href={`tel:${phone}`} className={"phone"}>
                {phone}
              </a>
              <a href={`mailto:${email}`} className={"email"}>
                {email}
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DigitalCard;
