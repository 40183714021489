import { createContext, useContext, useEffect, useState } from "react";
import firebase from "../firebase";

const UserContext = createContext({
  loading: true,
  user: null,
  userData: null,
  userLoaded: false,
  setUserData: () => null,
});

export const UserContextProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const [userLoaded, setUserLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      setUser(authUser);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      firebase.getUserData().then((data) => {
        setUserData(data);
        setUserLoaded(true);
        setLoading(false);
      });
    }
  }, [user]);

  const value = {
    loading,
    user,
    userData,
    userLoaded,
    setUserData,
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`);
  }
  return context;
};
