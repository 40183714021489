export const DEFAULT_CARD = {
  isDefault: true,
  name: "John Doe",
  avatar: "demo/logo512.png",
  title: "Senior Vice President, Example",
  phone: "+123456789",
  address: "22 Baker St, London, UK",
  email: "john@example.com",
  description:
    "I'm a business magnate, industrial designer, investor, and media proprietor.",
};

export const DEFAULT_SETTINGS = {
  font: {
    family: "Open Sans",
  },
  color: "#f4ebff",
  bgColor: "#12006c",
};
