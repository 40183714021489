import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { debounce } from "lodash-es";
import { DEFAULT_CARD, DEFAULT_SETTINGS } from "./data/card.data";

const firebaseui = require("firebaseui");

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth;
    this.ui = new firebaseui.auth.AuthUI(this.auth());
    this.firestore = app.firestore;
    this.storage = app.storage;

    this.auth()
      .setPersistence(app.auth.Auth.Persistence.LOCAL)
      .then(() => {
        this.auth().useDeviceLanguage();
      });

    this.saveSettings = debounce(this.saveCardSettings, 1000);
    this.saveData = debounce(this.saveCardData, 1000);
  }

  async getUserData() {
    const { uid, phoneNumber } = this.auth().currentUser;

    try {
      const userDataRef = await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .get();
      if (userDataRef.data()) {
        return userDataRef.data();
      } else {
        const userData = {
          cardData: {
            ...DEFAULT_CARD,
            phone: phoneNumber,
            whatsapp: phoneNumber,
            avatar: null,
            isDefault: false,
          },
          cardSettings: {
            ...DEFAULT_SETTINGS,
            color: "#000",
            bgColor: "#fff",
          },
          paid: false,
          createdAt: Date.now(),
        };
        await firebase.firestore().collection("users").doc(uid).set(userData);
        return userData;
      }
    } catch (e) {
      console.log(e);
    }
  }

  async saveCardData(data) {
    if (!this.auth().currentUser) {
      return false;
    }

    console.info("saving card", data);
    const { uid, phoneNumber } = this.auth().currentUser;
    data.phone = phoneNumber;

    try {
      await firebase.firestore().collection("users").doc(uid).update({
        cardData: data,
        updatedAt: Date.now(),
      });
      console.log("Card Data Updated");
    } catch (e) {
      console.log(e);
    }
  }

  async saveCardSettings(data) {
    if (!this.auth().currentUser) {
      return false;
    }

    console.info("saving settings", data);
    const { uid } = this.auth().currentUser;

    try {
      await firebase.firestore().collection("users").doc(uid).update({
        cardSettings: data,
        updatedAt: Date.now(),
      });
      console.log("Card Settings Updated");
    } catch (e) {
      console.log(e);
    }
  }

  async completePurchase(stripePayload) {
    if (!this.auth().currentUser) {
      return false;
    }

    const { uid } = this.auth().currentUser;

    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-828708855/RHHNCL3L6esCEPevlIsD",
        transaction_id: stripePayload?.paymentIntent?.id,
      });
    }

    try {
      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update({
          paid: true,
          payment: {
            id: stripePayload?.paymentIntent?.id,
          },
          updatedAt: Date.now(),
        });
    } catch (e) {
      console.log(e);
    }
  }
}

const firebase = new Firebase();
export default firebase;
