import { Spinner, ThemeProvider } from "@primer/components";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import DownloadCard from "./containers/DownloadCard/DownloadCard";
import "./firebase";
import "./index.css";
import MainLayout from "./layouts/header-content-footer";
import { UserContextProvider } from "./utils/useUser";

const Home = lazy(() => import("./containers/Home/Home"));
const Create = lazy(() => import("./containers/Create/Create"));
const Login = lazy(() => import("./containers/Login/Login"));
const Privacy = lazy(() => import("./containers/Legal/Privacy"));
const Terms = lazy(() => import("./containers/Legal/Terms"));
const Contact = lazy(() => import("./containers/Legal/Contact"));
const Refund = lazy(() => import("./containers/Legal/Refund"));
const PageNotFound = lazy(() => import("./containers/404/404"));

const WrapMainLayout = (Component, layoutProps) => (props) =>
  (
    <MainLayout {...layoutProps} {...props}>
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    </MainLayout>
  );

const Loading = () => (
  <div style={{ minHeight: "30vh", height: "30vh" }} className="container">
    <Spinner mt={5} />
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <UserContextProvider>
        <Router>
          <Switch>
            <Route
              exact
              path="/"
              render={WrapMainLayout(Home, { showFooter: true })}
            />

            <Route
              exact
              path="/login"
              render={WrapMainLayout(Login, { showFooter: true })}
            />

            <Route
              exact
              path="/create"
              render={WrapMainLayout(Create, { showFooter: true })}
            />

            <Route exact path="/card/:uid" component={DownloadCard} />

            {/*Legal*/}
            <Route
              exact
              path="/privacy"
              render={WrapMainLayout(Privacy, { showFooter: true })}
            />
            <Route
              exact
              path="/terms"
              render={WrapMainLayout(Terms, { showFooter: true })}
            />
            <Route
              exact
              path="/contact"
              render={WrapMainLayout(Contact, { showFooter: true })}
            />
            <Route
              exact
              path="/refund"
              render={WrapMainLayout(Refund, { showFooter: true })}
            />

            {/*404*/}
            <Route render={WrapMainLayout(PageNotFound)} />
          </Switch>
        </Router>
      </UserContextProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
