import React from "react";

import Header from "../../components/header";
import Footer from "../../components/footer";

class MainLayout extends React.Component {
  render() {
    return (
      <>
        <Header {...this.props} />
        <>{React.Children.toArray(this.props.children)}</>
        {this.props.showFooter && <Footer />}
      </>
    );
  }
}

export default MainLayout;
