import { Header } from "@primer/components";
import React from "react";
import "./styles.css";
import logo from "./../../assets/logo/logo512.png";

function NavHeader() {
  return (
    <Header>
      <Header.Item style={{ margin: 0, width: "100%" }}>
        <Header.Link href="/" style={{ margin: "0 auto" }}>
          <img src={logo} width={40} height={40} alt={"logo"} />
          <span style={{ marginLeft: 16, fontSize: 20, fontWeight: 800 }}>
            Biz Card Maker
          </span>
        </Header.Link>
      </Header.Item>
    </Header>
  );
}

export default NavHeader;
